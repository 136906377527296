

import { updateCardByProductIdState } from '@yggdrasil/Checkout/V2/CheckoutProductSelect/V2/checkoutProductSelect'
import { CF2Component, registerComponent } from 'javascript/lander/runtime'

export default class CheckoutV2 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}



  mount() {
  }

  initializeStoreDependentElements(store, computed) {
    const checkoutElements = this.getAllComponents()
    const linkedElements = [...document.querySelectorAll(`[data-linked-checkout-id=${this.id}]`)].map((el) => el.cf2_instance)
    const allElements = checkoutElements.concat(linkedElements)
    allElements.forEach((el) => {
      el?.initializeWithStore && el.initializeWithStore(store, computed)
    })
  }

  afterMount() {
    this.initializeStoreDependentElements(Checkout.store, Checkout.computed)

    const productVariantWithNoBump = globalThis.globalResourceData.productVariantWithNoBump
    if (productVariantWithNoBump) {
      const variantId = productVariantWithNoBump.id
      const productId = productVariantWithNoBump.product_id
      updateCardByProductIdState(productId, { variantId })
    }
  }



}

registerComponent('Checkout/V2', CheckoutV2)
window["CheckoutV2"] = CheckoutV2

